<template>
  <v-expansion-panels v-model="openPanel">
    <v-expansion-panel>
      <v-expansion-panel-title class="px-10" expand-icon="mdi-chevron-down">
        <h3>
          <slot name="header" />
        </h3>
        <template #actions="{ expanded }">
          <v-avatar color="grey-darken-3" size="30" class="elevation-3">
            <v-icon
              color="white"
              :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
            />
          </v-avatar>
        </template>
      </v-expansion-panel-title>
      <v-expansion-panel-text class="px-5">
        <v-divider class="mt-1 mb-4" />
        <slot name="content" />
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "MainExpansionPanel",
  components: {},
  data: () => ({
    openPanel: 0,
  }),
};
</script>

<style></style>
