<template>
  <div class="mb-5">
    <v-app-bar class="wli-top pt-6 pb-3" scroll-behavior="hide">
      <v-container>
        <v-row>
          <v-col cols="3" class="">
            <img
              id="top-logo"
              :src="require('../../assets/logo.svg')"
              class="ms-4 ms-md-0"
            />
          </v-col>
          <v-col cols="9" class="pt-8 text-right d-none d-md-block">
            <v-btn
              v-for="(item, index) in authMenu"
              :key="index"
              class="mr-2 wli-menu-link text-none"
              variant="text"
            >
              <router-link class="font-weight-bold" :to="item.link">
                {{ item.name }}
              </router-link>
            </v-btn>
            <LoginDialogButtonVue
              :button-text="'Zugangscode eingeben'"
              :outlined="true"
            />
          </v-col>
          <v-col cols="9" class="text-right pt-4 d-md-none">
            <v-app-bar-nav-icon size="x-large" @click.stop="drawer = !drawer" />
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" temporary>
      <v-list
        v-model:selected="selectedMenuItems"
        nav
        density="compact"
        class="mt-10"
      >
        <v-list-item
          v-for="(item, index) in authMenu"
          :key="index"
          :to="item.link"
          :value="item.link"
        >
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>
        <LoginDialogMobile />
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import authControlMixin from "../../mixins/authControlMixin";
import LoginDialogButtonVue from "@/components/participants/LoginDialogButton";
import LoginDialogMobile from "@/components/participants/LoginDialogMobile";
const TOKEN_STATE_ALL_SOLVED = 3;
export default {
  name: "AppBar",
  components: { LoginDialogButtonVue, LoginDialogMobile },
  mixins: [authControlMixin],
  data: () => ({
    drawer: false,
    menu: [
      {
        name: "Startseite",
        link: "/",
        is_private: false,
        is_after_all_solved: false,
      },
      {
        name: "Anleitung",
        link: "/anleitung/",
        is_private: false,
        is_after_all_solved: false,
      },
      {
        name: "Test",
        link: "/test/",
        is_private: true,
        is_after_all_solved: false,
      },
      {
        name: "Auswertung",
        link: "/auswertung/1/",
        is_private: true,
        is_after_all_solved: true,
      },
      {
        name: "Vergleich",
        link: "/vergleich/1/",
        is_private: true,
        is_after_all_solved: true,
      },
    ],
    selectedMenuItems: [],
  }),

  computed: {
    authMenu() {
      let tempMenu = [];
      for (let i = 0; i < this.menu.length; i++) {
        if (this.menu[i].is_private) {
          if (this.isLoggedIn) {
            if (
              !this.menu[i].is_after_all_solved ||
              (this.menu[i].is_after_all_solved &&
                this.tokenState == TOKEN_STATE_ALL_SOLVED)
            ) {
              tempMenu.push(this.menu[i]);
            }
          }
        } else {
          tempMenu.push(this.menu[i]);
        }
      }
      return tempMenu;
    },
  },

  methods: {},
};
</script>

<style>
.wli-top {
  background-color: white !important;
  border-bottom: 0px solid #666 !important;
}
.wli-menu-link a {
  text-decoration: none !important;
  color: #757575 !important;
  font-size: 16px;
}

.wli-menu-link a:hover,
.wli-menu-link .router-link-exact-active {
  color: #283593 !important;
}

#top-logo {
  height: 64px;
}
</style>
