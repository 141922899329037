<template>
  <v-btn
    rounded
    color="primary"
    class="mr-3 text-none"
    :size="(xLarge && 'x-large') || (large && 'large')"
    :to="to"
    @click="$emit('click')"
  >
    <slot name="icon_left" />
    {{ buttonText }}
    <slot name="icon_right" />
  </v-btn>
</template>

<script>
export default {
  name: "Button",
  props: {
    large: {
      type: Boolean,
      default: null,
    },
    xLarge: {
      type: Boolean,
      default: null,
    },
    to: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      required: true,
    },
  },
  emits: ["click"],
};
</script>

<style lang="scss" scoped></style>
