<template>
  <div>
    <v-text-field
      v-model="compareToken"
      :error-messages="errorMessage"
      :readonly="isCompareTokenValid"
      :disabled="isCompareTokenValid"
      label="Token von anderem Test"
    />

    <v-btn
      v-if="!isCompareTokenValid"
      color="primary"
      class="text-none"
      @click="setToken"
    >
      Code setzen
    </v-btn>

    <v-btn v-else color="error" @click="deleteToken"> Code löschen </v-btn>
  </div>
</template>

<script>
import authPageMixin from "../../mixins/authPageMixin";
import { useSessionStore } from "@/stores/session";
import axios from "axios";
export default {
  mixins: [authPageMixin],
  emits: ["validatedToken", "deletedToken"],
  data: () => ({
    compareToken: "",
    errorMessage: "",
    isCompareTokenValid: false,
    session: null,
  }),
  computed: {},
  created() {
    this.session = useSessionStore();
  },
  mounted() {
    this.fetchCompareToken();
  },
  methods: {
    setToken() {
      let url = "compare/check-token/" + this.compareToken + "/";
      axios
        .get(url, {
          headers: this.getAuthHeader(),
        })
        .then((response) => {
          this.success(response);
        })
        .catch(() => {
          this.errorMessage = "Token ungültig";
        });
    },
    success(response) {
      this.session.setCompareToken(
        response.data.compare_token,
        response.data.compare_hash,
      );
      this.errorMessage = "";
      this.isCompareTokenValid = true;
      this.$emit("validatedToken");
    },
    deleteToken() {
      this.session.resetCompareToken();
      this.compareToken = "";
      this.isCompareTokenValid = false;
      this.$emit("deletedToken");
    },
    fetchCompareToken() {
      if (this.session.compareToken) {
        this.compareToken = this.session.compareToken;
        this.isCompareTokenValid = true;
        this.$emit("validatedToken");
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
