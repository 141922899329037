<template>
  <v-footer class="wli-footer">
    <v-snackbar
      v-model="createSnackbar"
      color="green-darken-1"
      location="right"
    >
      Ihr Feedback wurde erfolgreich übermittelt.
    </v-snackbar>
    <v-container class="mt-10">
      <v-row>
        <v-col class="text-center pt-5" cols="12" lg="4">
          &copy; {{ new Date().getFullYear() }}
          <strong>Cornelsen Verlag GmbH</strong>
        </v-col>
        <v-col cols="12" lg="4" class="text-center pt-4">
          <img id="bottom-logo" :src="require('../../assets/cv-logo.svg')" />
        </v-col>
        <v-col class="text-center" cols="12" lg="4">
          <v-btn
            v-for="(item, index) in footerMenu"
            :key="index"
            variant="plain"
            class="my-2 footer-menu text-none"
            :to="item.link"
            @click="scrollToTop()"
          >
            {{ item.title }}
          </v-btn>
          <FeedbackDialog @createSnackbar="createSnackbar = true" />
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import authControlMixin from "@/mixins/authControlMixin";
import FeedbackDialog from "@/components/participants/FeedbackDialog";
export default {
  name: "Footer",
  components: { FeedbackDialog },
  mixins: [authControlMixin],
  data: () => ({
    footerMenu: [
      {
        title: "Startseite",
        link: "/",
      },
      {
        title: "Datenschutz",
        link: "/datenschutz/",
      },
      {
        title: "Impressum",
        link: "/impressum/",
      },
    ],
    createSnackbar: false,
  }),
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style>
.wli-footer {
  background-color: white !important;
  border-top: 1px solid #666 !important;
}
#bottom-logo {
  height: 35px;
}
</style>
