<template>
  <v-dialog v-model="dialog" width="500" class="">
    <template #activator="{ props }">
      <v-btn v-bind="props" rounded color="primary" size="large">
        Test beenden
      </v-btn>
    </template>

    <v-card class="pa-5 bg-grey-lighten-3">
      <HeadlineSmall title="Test Abschluss bestätigen" />
      <p>
        Nach der Bestätigung für den Test Abschluss, können Sie keine Antworten
        mehr ändern.
      </p>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          rounded
          variant="outlined"
          color="primary"
          size="large"
          @click="dialog = false"
        >
          Abbrechen
        </v-btn>
        <v-btn rounded color="primary" size="large" @click="finishTest()">
          Bestätigen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import authPageMixin from "../../mixins/authPageMixin";
import HeadlineSmall from "./../layout/HeadlineSmall";
import axios from "axios";

export default {
  name: "StepperDialog",

  components: { HeadlineSmall },
  mixins: [authPageMixin],
  props: [],
  emits: ["onSuccess"],
  data: () => ({
    dialog: false,
  }),
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    finishTest() {
      let url = "participants/finish-test/";
      axios
        .post(
          url,
          {},
          {
            headers: this.getAuthHeader(),
          },
        )
        .then(() => {
          this.success();
        })
        .catch(() => {
          this.errorMessage = "Token ungültig";
        });
    },
    success() {
      this.$emit("onSuccess");
      this.dialog = false;
    },
  },
};
</script>
