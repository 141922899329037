<template>
  <div class="card-wrapper">
    <v-snackbar
      v-model="updateSnackbar"
      color="green-darken-1"
      location="right"
    >
      Ant wort für Frage {{ lastAnsweredQuestionPostion }} wurde aktualisiert.
    </v-snackbar>
    <v-snackbar
      v-model="createSnackbar"
      color="green-darken-1"
      location="right"
    >
      Antwort für Frage {{ lastAnsweredQuestionPostion }} wurde erstellt.
    </v-snackbar>
    <v-snackbar v-model="errorSnackbar" color="error-darken-1" location="right">
      Antwort für Frage {{ lastAnsweredQuestionPostion }} konnte nicht an den
      Server übermittelt werden.
    </v-snackbar>
    <!-- desktop -->

    <v-card
      class="px-8 py-3 mb-6 wli-question-card"
      elevation="3"
      height="100%"
      variant="elevated"
      color=""
      hover
    >
      <v-row>
        <v-col>
          <v-radio-group
            v-model="choosenAnswer"
            class="wli-radio-group"
            style="display: block"
          >
            <v-col cols="12" class="">
              <v-row>
                <v-col>
                  <p class="pt-2 px-0 font-weight-bold text-left">
                    Aussage {{ question.position }}
                  </p>
                </v-col>
                <v-col class="text-right">
                  <v-scroll-x-transition>
                    <v-avatar
                      v-if="isAnswered"
                      color="green"
                      size="48"
                      class="elevation-3"
                    >
                      <font-awesome-icon icon="fa-solid fa-check" size="xl" />
                    </v-avatar>
                  </v-scroll-x-transition>
                  <v-avatar v-if="!isAnswered" color="grey-darken-3" size="48">
                    <font-awesome-icon icon="fa-pen" size="xl" />
                  </v-avatar>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pr-5">
              <v-card
                flat
                color=""
                class="pl-0 pt-5 pb-5 pr-5 question-inner-card d-flex align-center"
              >
                <p class="question-text">
                  {{ question.pool_text }}
                </p>
              </v-card>
            </v-col>
            <v-col cols="12" class="d-flex flex-column align-start">
              <v-radio
                v-for="c in answerChoices"
                :key="c.key"
                :value="c.key"
                :label="c.title"
                :disabled="tokenState === usedTokenState"
                :class="'mb-0 pb-0 mt-0 pt-0'"
              />
            </v-col>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import detectMobileMixin from "./../../mixins/detectMobileMixin";
import authPageMixin from "../../mixins/authPageMixin";
import authControlMixin from "../../mixins/authControlMixin";
import { anwereChoicesList } from "@/constants";
import { useSessionStore } from "@/stores/session";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
const USED_TOKEN_STATE = 3;

export default {
  name: "DesktopRow",
  components: { FontAwesomeIcon },
  mixins: [detectMobileMixin, authPageMixin, authControlMixin],
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:question"],
  data: () => ({
    choosenAnswer: null,
    answerChars: ["A", "B", "C", "D", "E"],
    answerChoices: [],
    updateSnackbar: false,
    createSnackbar: false,
    errorSnackbar: false,
    lastAnsweredQuestionPostion: null,
    usedTokenState: USED_TOKEN_STATE,
    state: null,
  }),
  computed: {
    compQuestion: {
      get() {
        return this.question;
      },
      set(value) {
        this.$emit("update:question", value);
      },
    },
    isAnswered() {
      if (this.question.answer != null) {
        return true;
      }
      return false;
    },
  },
  watch: {
    choosenAnswer() {
      if (this.question.answer == null) {
        this.createAnswer();
      } else {
        if (this.question.answer.answer_char !== this.choosenAnswer) {
          this.updateAnswer();
        }
      }
    },
  },
  mounted() {
    this.initChoosenAnswer();
    this.answerChoices = anwereChoicesList;
  },
  created() {
    this.session = useSessionStore();
  },
  methods: {
    initChoosenAnswer() {
      if (this.question.answer != null) {
        this.choosenAnswer = this.question.answer.answer_char;
      } else {
        this.choosenAnswer = null;
      }
    },
    createAnswer() {
      this.lastAnsweredQuestionPostion = this.question.position;
      let data = {
        participant: this.session.participantId,
        question: this.question.id,
        answer_char: this.choosenAnswer,
      };
      axios
        .post("answers/answers/", data, {
          headers: this.getAuthHeader(),
        })
        .then((response) => {
          this.question.answer = response.data;
          this.createSnackbar = true;
        })
        .catch(() => {
          this.errorSnackbar = true;
          this.choosenAnswer = this.question.answer.answer_char;
        });
    },
    updateAnswer() {
      this.question.answer.answer_char = this.choosenAnswer;
      let url = "answers/answers/" + this.question.answer.id + "/";
      this.lastAnsweredQuestionPostion = this.question.position;
      axios
        .put(url, this.question.answer, {
          headers: this.getAuthHeader(),
        })
        .then((response) => {
          this.question.answer = response.data;
          this.updateSnackbar = true;
        })
        .catch(() => {
          // Handle the error scenario
          this.errorSnackbar = true;
        });
    },
  },
};
</script>
<style>
.wli-radio-group {
  flex: 1 1 auto !important;
}
.wli-radio-group .v-radio {
  justify-content: center !important;
  margin-right: 0px !important;
}
.v-input--radio-group__input {
  align-items: center !important;
}
.question-text {
  font-size: 18px;
}
.card-wrapper {
  height: 100%;
}
.question-inner-card {
  min-height: 200px;
}
.v-item--active .v-label {
  font-weight: bold;
}
.wli-question-card {
  background-color: #fff !important;
}
</style>
