<template>
  <v-card class="pa-5" color="" height="100%">
    <slot />
  </v-card>
</template>

<script>
export default {
  name: "InnerCard",
  data: () => ({}),
};
</script>

<style></style>
