<template>
  <div id="history-compare-table">
    <inner-card v-if="!isLoading">
      <v-data-table-virtual
        :headers="tableHeader"
        :items="tableData"
        class=""
        disable-sort
      >
        <template #item="{ item }">
          <tr>
            <td class="text-center">
              <b>{{ item.percent_value }} %</b>
            </td>
            <td
              v-for="(col, index) in pointCols"
              :key="index"
              class="text-center"
            >
              <TablePoints
                :points="item[col].points"
                :marker="item[col].marker"
              />
            </td>
            <td class="text-center">
              <img
                :src="getCupFilename(item.percent_value)"
                class="history-cup"
              />
            </td>
          </tr>
        </template>
      </v-data-table-virtual>
    </inner-card>
    <v-progress-circular
      v-if="isLoading"
      indeterminate
      :size="50"
      color="primary"
    />
  </div>
</template>

<script>
import authPageMixin from "../../mixins/authPageMixin";
import fetchResultsMixin from "../../mixins/fetchResultsMixin";
import TablePoints from "../../components/evaluation/TablePoints";
import InnerCard from "../../components/layout/InnerCard";
import { useSessionStore } from "@/stores/session";
import axios from "axios";

export default {
  name: "HistoryCompareTable",
  components: { TablePoints, InnerCard },
  mixins: [authPageMixin, fetchResultsMixin],
  props: {
    areTwoTokens: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    tableData: [],
    pointCols: ["hal", "mot", "zei", "kon", "ang", "wes", "inf", "pst", "sko"],
    tableHeader: [
      { title: "", key: "percent_value", align: "center" },
      { title: "Hal", key: "hal", align: "center" },
      { title: "Mot", key: "mot", align: "center" },
      { title: "Zei", key: "zei", align: "center" },
      { title: "Kon", key: "kon", align: "center" },
      { title: "Ang", key: "ang", align: "center" },
      { title: "Wes", key: "wes", align: "center" },
      { title: "Inf", key: "inf", align: "center" },
      { title: "Pst", key: "pst", align: "center" },
      { title: "Sko", key: "sko", align: "center" },
      { title: "", key: "percent_value" },
    ],
    isLoading: false,
    session: null,
  }),
  mounted() {
    this.fetchTableData();
    this.initResults();
  },
  created() {
    this.session = useSessionStore();
  },
  methods: {
    fetchTableData() {
      this.isLoading = true;
      let url = "evaluation/compare-list/";
      if (this.areTwoTokens) {
        url =
          "compare/matched-compare-tables/?compare-token=" +
          this.session.compareHash;
      }
      axios
        .get(url, {
          headers: this.getAuthHeader(),
        })
        .then((response) => {
          this.tableData = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error.response ? error.response.data : error);
        });
    },
    getTrClass(percentValue) {
      if (percentValue <= 45) {
        return "grey lighten-3";
      } else if (percentValue <= 70) {
        return "grey-lighten-2";
      } else {
        return "grey lighten-1";
      }
    },
    getCupFilename(percentValue) {
      if (percentValue <= 45) {
        return require("../../assets/Pokal_Icon_Rot.svg");
      } else if (percentValue <= 70) {
        return require("../../assets/Pokal_Icon_Gelb.svg");
      } else {
        return require("../../assets/Pokal_Icon_Gruen.svg");
      }
    },
  },
};
</script>
<style lang="scss">
#history-compare-table {
  overflow: scroll;
}
.history-cup {
  width: 25px;
  height: 25px;
}
th span {
  font-size: 16px !important;
}
td {
  font-size: 15px !important;
}
</style>
