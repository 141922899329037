<template>
  <div v-if="isLoggedIn">
    <HeadlineSmall :title="'Feedback'" />
    <v-form ref="loginForm" @submit.prevent="sendFeedback">
      <v-textarea
        v-model="text"
        :error-messages="errorMessage"
        counter
        variant="outlined"
        label="Text hier eingeben"
      />
      <Button
        v-model="text"
        type="submit"
        :button-text="'Senden'"
        :large="true"
      />
    </v-form>
  </div>
</template>

<script>
import HeadlineSmall from "@/components/layout/HeadlineSmall";
import authControlMixin from "@/mixins/authControlMixin";
import Button from "@/components/layout/Button";
import authPageMixin from "@/mixins/authPageMixin";
import { useSessionStore } from "@/stores/session";
import axios from "axios";
export default {
  name: "FeedbackForm",
  components: { HeadlineSmall, Button },
  mixins: [authControlMixin, authPageMixin],
  emits: ["closeDialog"],
  data: () => ({
    text: "",
    errorMessage: "",
    session: null,
  }),
  created() {
    this.session = useSessionStore();
  },
  methods: {
    sendFeedback() {
      let data = {
        text: this.text,
        participant: this.session.participantId,
      };
      axios
        .post("feedback/send-feedback/", data, {
          headers: this.getAuthHeader(),
        })
        .then((response) => {
          console.log(response);
          this.text = "";
          this.$emit("closeDialog");
        })
        .catch((error) => {
          console.log(error);
          this.errorMessage = "Bitte geben Sie ihr Feedback ein.";
        });
    },
  },
};
</script>

<style scoped lang="scss"></style>
