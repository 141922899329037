<template>
  <div class="history-table-points">
    <span v-if="marker != null" :class="getClass()">{{ points }}</span>
    <span v-if="marker == null">{{ points }}</span>
  </div>
</template>

<script>
export default {
  name: "TablePoints",
  props: {
    points: {
      type: String,
      required: true,
    },
    marker: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({}),
  computed: {},
  mounted() {},
  methods: {
    getClass() {
      let color = "";
      if (this.marker === 1) {
        color = "text-primary font-weight-bold";
      }
      if (this.marker === 2) {
        color = "text-brown text--accent-4 font-weight-bold";
      }
      if (this.marker === 3) {
        color = "text-purple text--accent-4 font-weight-bold";
      }
      return color;
    },
  },
};
</script>

<style></style>
