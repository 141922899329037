<template>
  <div class="category-overview">
    <v-data-table
      :items="items"
      :headers="headers"
      item-value="title"
      class="mt-5"
    >
      <template #bottom />
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "CategoryOverview",
  props: {
    items: { type: Array, default: () => [] },
  },
  data: () => ({
    headers: [
      {
        title: "Schlüssel",
        key: "key",
        align: "start",
      },
      {
        title: "Titel",
        key: "title",
      },
      {
        title: "Beschreibung",
        key: "description",
        sortable: false,
      },
    ],
  }),
};
</script>

<style lang="scss">
.category-overview {
  width: 90%;
}
</style>
