<template>
  <div class="pt-1">
    <v-row>
      <v-col cols="3">
        <v-btn
          color="primary"
          :disabled="prev == null"
          :to="prev"
          class="rounded-circle"
          size="40"
        >
          <font-awesome-icon icon="fa-solid fa-chevron-left" size="xl" />
        </v-btn>
      </v-col>
      <v-col cols="6">
        <h2 class="text-center">Teil {{ page }} / {{ all }}</h2>
      </v-col>
      <v-col cols="3">
        <v-btn
          color="primary"
          :disabled="next == null"
          :to="next"
          class="rounded-circle"
          size="40"
        >
          <font-awesome-icon icon="fa-solid fa-chevron-right" size="xl" />
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: String,
      required: true,
    },
    all: {
      type: String,
      required: true,
    },
    prev: {
      type: String,
      default: null,
    },
    next: {
      type: String,
      default: null,
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
