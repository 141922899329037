<template>
  <div>
    <Headline title="Anleitung für den WLI-Test" />
    <v-container>
      <v-row>
        <v-col cols="12" md="5">
          <main-card>
            <v-img
              alt="Intro 1"
              class=""
              cover
              :src="require('../assets/fragen-1-6.jpg')"
            />
          </main-card>
        </v-col>
        <v-col cols="12" md="7">
          <main-card>
            <HeadlineSmall title="Aussagen treffen" />
            <p>
              Beziehen Sie Stellung zu 72 Aussagen über Ihr Lernverhalten.
              Klicken Sie bei jeder Aussage die Möglichkeit an, die auf Ihr
              Lernverhalten am besten zutrifft. Zur Auswahl stehen die
              Antwortmöglichkeiten:
            </p>
            <ol type="a" class="ms-7">
              <li>Sehr typisch für mich</li>
              <li>Eher typisch für mich</li>
              <li>Einigermaßen typisch für mich</li>
              <li>Eher untypisch für mich</li>
              <li>Sehr untypisch für mich</li>
            </ol>
            <p />
            <p>Nur ein Klick pro Aussage ist erlaubt.</p>
          </main-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <main-card>
            <HeadlineSmall
              title="Und noch ein paar nützlich Hinweise zur Durchführung:"
            />
            <ul class="ms-6">
              <li>
                Es gibt keine richtigen oder falschen Antworten. Es geht nur
                darum, dass Sie Ihr tatsächliches Lernverhalten ausdrücken. Wie
                Sie sich eventuell verhalten könnten, ist jetzt nicht gefragt.
              </li>
              <li>
                Falls Sie doch einmal den Eindruck haben, die falsche Auswahl
                getroffen zu haben, korrigieren Sie diese.
              </li>
              <li>
                Beziehen Sie Ihre Antworten auf den Zeitraum Ihrer Lernphase, in
                dem Sie sich gerade befinden, z.B.: Schule, Studium,
                Berufsausbildung.
              </li>
              <li>
                Vielleicht gewinnen Sie den Eindruck, dass mehrmals dasselbe
                gefragt wird. Lassen Sie sich dadurch nicht verwirren. In
                Wirklichkeit werden viele verschiedene Aspekte des Lernens
                erfragt, die aber selbstverständlich miteinander verwandt sein
                können.
              </li>
              <li>
                Wenn Sie zu allen 72 Aussagen Position bezogen haben, beenden
                Sie den Test. Achtung! Nach dem Beenden können Sie Ihre
                Stellungnahme zwar noch einsehen, aber nicht mehr verändern.
              </li>
            </ul>
          </main-card>
        </v-col>
      </v-row>
      <div
        v-if="checkTestButton()"
        class="mt-10 text-center wli-dashed-border-top py-4"
      >
        <Button x-large :to="'/test/'" :button-text="'zum Test'">
          <template #icon_right>
            <font-awesome-icon
              icon="fa-solid fa-chevron-right"
              size="lg"
              :class="'ms-3'"
            />
          </template>
        </Button>
      </div>
    </v-container>
  </div>
</template>

<script>
import Headline from "./../components/layout/Headline";
import HeadlineSmall from "./../components/layout/HeadlineSmall";
import authPageMixin from "../mixins/authPageMixin";
import MainCard from "@/components/layout/MainCard";
import Button from "@/components/layout/Button";
import { useSessionStore } from "@/stores/session";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "Intro",
  components: {
    Button,
    MainCard,
    Headline,
    HeadlineSmall,
    FontAwesomeIcon,
  },
  mixins: [authPageMixin],
  data: () => ({
    session: null,
  }),
  created() {
    this.session = useSessionStore();
  },
  methods: {
    checkTestButton() {
      return !!this.session.isActive;
    },
  },
};
</script>
<style>
.wli-dashed-border-top {
  border-top: 2px dashed #fff;
}
</style>
