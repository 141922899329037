<template>
  <div>
    <h3>{{ title }}</h3>
    <v-divider class="mt-1 mb-4" />
  </div>
</template>

<script>
export default {
  name: "HeadlineSmall",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data: () => ({}),
  computed: {},
};
</script>

<style></style>
