<template>
  <div>
    <Headline title="Test über Ihr Lernverhalten" />
    <v-container>
      <Stepper :questions="questions" :questions-counter="questionsCounter" />
    </v-container>
    <v-container>
      <v-row id="top-pagination" class="px-0">
        <v-col cols="12">
          <main-card>
            <v-row>
              <v-col cols="12" md="3">
                <v-row>
                  <v-col cols="3">
                    <v-btn
                      color="primary"
                      :disabled="page == 1"
                      class="rounded-circle"
                      size="40"
                      @click="change_question_page(-1)"
                    >
                      <font-awesome-icon
                        icon="fa-solid fa-chevron-left"
                        size="xl"
                      />
                    </v-btn>
                  </v-col>
                  <v-col cols="6">
                    <p class="text-center paging-line-text pt-1 mb-0">
                      Seite {{ page }} / {{ maxPages }}
                    </p>
                  </v-col>
                  <v-col cols="3">
                    <v-btn
                      color="primary"
                      class="rounded-circle"
                      size="40"
                      :disabled="disabledNext()"
                      @click="change_question_page(1)"
                    >
                      <font-awesome-icon
                        icon="fa-solid fa-chevron-right"
                        size="xl"
                      />
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="9"
                class="justify-end d-flex align-center paging-line-text"
              >
                Aussagen
                <AvatarNumber :number="from + 1" class="px-3" /> bis
                <AvatarNumber :number="to" class="px-3" />
              </v-col>
            </v-row>
          </main-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          v-for="(question, i) in pagedQuestions"
          :key="question.id"
          cols="12"
          md="6"
          lg="4"
        >
          <DesktopRow :question="pagedQuestions[i]" />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right" cols="3">
          <v-btn
            color="primary"
            :disabled="page == 1"
            class="rounded-circle"
            size="40"
            @click="change_question_page(-1)"
          >
            <font-awesome-icon icon="fa-solid fa-chevron-left" size="xl" />
          </v-btn>
        </v-col>
        <v-col cols="6">
          <p class="text-center paging-line-text pt-1 mb-0">
            Seite {{ page }} / {{ maxPages }}
          </p>
        </v-col>
        <v-col cols="3">
          <v-btn
            color="primary"
            class="rounded-circle"
            size="40"
            :disabled="disabledNext()"
            @click="change_question_page(1)"
          >
            <font-awesome-icon icon="fa-solid fa-chevron-right" size="xl" />
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import DesktopRow from "./../components/questions/DesktopRow";
import Stepper from "./../components/questions/Stepper";
//import Paging from "./../components/questions/Paging";
import Headline from "./../components/layout/Headline";
import detectMobileMixin from "../mixins/detectMobileMixin";
import authPageMixin from "../mixins/authPageMixin";
import { anwereChoicesList } from "@/constants";
import MainCard from "./../components/layout/MainCard";
import AvatarNumber from "./../components/layout/AvatarNumber";
import axios from "axios";
export default {
  name: "Questions",
  components: {
    DesktopRow,
    //Paging,
    Headline,
    Stepper,
    MainCard,
    AvatarNumber,
  },
  mixins: [detectMobileMixin, authPageMixin],
  data: () => ({
    page: 1,
    answerChoices: [],
    questions: [],
  }),
  computed: {
    questionsCounter() {
      return this.questions.length;
    },
    from() {
      return (this.page - 1) * this.questionsProPage;
    },
    to() {
      return this.from + this.questionsProPage;
    },
    pagedQuestions() {
      return this.questions.slice(this.from, this.to);
    },
    questionsProPage() {
      if (this.isMobile) {
        return 1;
      }
      return 6;
    },
    maxPages() {
      return this.questionsCounter / this.questionsProPage;
    },
  },
  watch: {
    maxPages(value) {
      if (value > 0) {
        this.initPage();
      }
    },
  },
  mounted() {
    this.fetch_questions();
    this.answerChoices = anwereChoicesList;
  },
  methods: {
    fetch_questions() {
      axios
        .get("questions/questions/", {
          headers: this.getAuthHeader(),
        })
        .then((response) => {
          this.questions = response.data;
        })
        .catch((error) => {
          console.log(error.response ? error.response.data : error);
        });
    },
    change_question_page(direction) {
      this.page = this.page + direction;
      document
        .getElementById("top-pagination")
        .scrollIntoView({ behavior: "smooth", block: "start" });
    },
    areAllQuestionsAnsweredOfCurrentPage() {
      for (let i = 0; i < this.pagedQuestions.length; i++) {
        if (this.pagedQuestions[i].answer == null) {
          return false;
        }
      }
      return true;
    },
    disabledNext() {
      let queryA = this.page == this.questionsCounter / this.questionsProPage;
      let queryB = !this.areAllQuestionsAnsweredOfCurrentPage();
      if (queryA || queryB) {
        return true;
      }
      return null;
    },
    initPage() {
      let questionNumber = this.$route.query.question;
      if (questionNumber) {
        for (let p = 1; p <= this.maxPages; p++) {
          let pageFrom = (p - 1) * this.questionsProPage + 1;
          let pageTo = pageFrom + this.questionsProPage;
          if (questionNumber >= pageFrom && questionNumber < pageTo) {
            this.page = p;
            break;
          }
        }
      }
    },
  },
};
</script>
<style>
.wli-paging {
  border-top: 2px dashed #aaa;
}
.wli-answer-text p {
  font-style: italic;
  font-size: 16px;
}
.paging-line-text {
  font-size: 22px;
}
</style>
