<template>
  <div class="page-headline">
    <v-container class="mt-5">
      <v-row>
        <v-col cols="12">
          <main-card>
            <v-row>
              <v-col cols="12" md="8">
                <h1 :class="classStr">
                  {{ title }}
                </h1>
              </v-col>
              <v-col cols="12" md="4" class="text-center">
                <ContentPaging :page="page" all="3" :next="next" :prev="prev" />
              </v-col>
            </v-row>
          </main-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ContentPaging from "../layout/ContentPaging";
import MainCard from "@/components/layout/MainCard";
export default {
  name: "CompareHeadline",
  components: { MainCard, ContentPaging },
  props: {
    title: {
      type: String,
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
    next: {
      type: [String, Function],
      required: true,
    },
    prev: {
      type: String,
      required: true,
    },
  },
  data: () => ({}),
  computed: {
    classStr() {
      if (this.windowInnerWith < 960) {
        return "  text-center";
      }
      return " ";
    },
    windowInnerWith() {
      return window.innerWidth;
    },
  },
};
</script>

<style></style>
