import { useSessionStore } from "@/stores/session";

let authControlMixin = {
  data: () => ({
    isLoggedIn: false,
    tokenState: 3,
    session: null,
  }),
  computed: {},
  created() {
    this.session = useSessionStore();
  },
  methods: {
    logout() {
      this.session.$reset();
      if (this.$route.path != "/") {
        this.$router.push("/");
      }
      //location.reload();
    },
    initRefreshInterval() {
      let self = this;
      this.refreshInterval = setInterval(() => {
        if (self.session.isActive) {
          self.isLoggedIn = true;
          self.tokenState = self.session.tokenState;
        } else {
          self.isLoggedIn = false;
        }
      }, 500);
    },
  },
  beforeUnmount() {
    clearInterval(this.refreshInterval);
  },
  mounted() {
    this.initRefreshInterval();
  },
};

export default authControlMixin;
