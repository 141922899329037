<template>
  <v-stepper :model-value="stepperModel">
    <v-stepper-header>
      <v-stepper-item
        :complete="stepperModel > 0"
        value="1"
        title="72 Antworten vergeben"
        :color="'primary'"
      />

      <v-divider />

      <v-stepper-item
        :complete="stepperModel > 1"
        value="2"
        title="Test abschließen"
        :color="stepperModel > 0 ? 'primary' : ''"
      />

      <v-divider />

      <v-stepper-item
        value="3"
        :color="stepperModel > 1 ? 'primary' : ''"
        title="Auswertung und Vergleich"
      />
    </v-stepper-header>

    <v-stepper-window>
      <v-stepper-window-item value="1">
        <v-card class="mb-12 pa-5" color="grey-lighten-2">
          <v-row>
            <v-col cols="12" sm="6">
              <HeadlineSmall title="Anleitung" />
              <p>
                Beziehen Sie Stellung zu 72 Aussagen über Ihr Lernverhalten.
                Klicken Sie bei jeder Aussage die Möglichkeit an, die auf Ihr
                Lernverhalten am besten zutrifft. Sie können den Test erst
                beenden, wenn alle Fragen beantwortet sind.
              </p>
            </v-col>
            <v-col>
              <HeadlineSmall title="Fortschritt" />
              <ProgressBar
                :questions="questions"
                :questions-counter="questionsCounter"
                :answered-count="answeredCount"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-stepper-window-item>

      <v-stepper-window-item value="2">
        <v-card class="mb-12 pa-5" color="grey-lighten-2">
          <v-row>
            <v-col>
              <HeadlineSmall title="Test abschließen" />
              <p>
                Sie haben alle Antworten abgegeben. Jetzt können Sie den Test
                abschließen. Danach können Sie keine Änderungen mehr am Test
                machen.
              </p>
            </v-col>
            <v-col>
              <HeadlineSmall title="Button klicken" />
              <StepperDialog @onSuccess="onDialogSuccess" />
            </v-col>
          </v-row>
        </v-card>
      </v-stepper-window-item>

      <v-stepper-window-item value="3">
        <v-card class="mb-12 pa-5" color="grey-lighten-2">
          <v-row>
            <v-col>
              <HeadlineSmall title="Test abgeschlossen!" />
              <p>Herzlichen Glückwunsch, Sie haben den Test abgeschlossen.</p>
            </v-col>
            <v-col>
              <HeadlineSmall title="Auswertung ansehen" />
              <p>Die Auswertung zu Ihrem Test kann jetzt angesehen werden.</p>
              <Button
                :large="true"
                :to="'/auswertung/1/'"
                :button-text="'Auswertung'"
              />
            </v-col>
            <v-col>
              <HeadlineSmall title="Vergleich machen" />
              <p>
                Sie können den Test auch mit einem früheren Durchlauf
                vergleichen.
              </p>
              <Button
                :large="true"
                :to="'/vergleich/1/'"
                :button-text="'Vergleich'"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-stepper-window-item>
    </v-stepper-window>
  </v-stepper>
</template>

<script>
import ProgressBar from "./ProgressBar";
import HeadlineSmall from "./../layout/HeadlineSmall";
import authPageMixin from "../../mixins/authPageMixin";
import Button from "@/components/layout/Button";
import StepperDialog from "@/components/questions/StepperDialog";
import { useSessionStore } from "@/stores/session";

const TOKEN_STATE_USED = 3;
export default {
  name: "Stepper",
  components: {
    Button,
    ProgressBar,
    HeadlineSmall,
    StepperDialog,
  },
  mixins: [authPageMixin],
  props: {
    questions: {
      type: Array,
      required: true,
    },
    questionsCounter: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    accessTokenState: null,
    session: null,
    isMobile: false,
  }),
  computed: {
    answeredCount() {
      let counter = 0;
      for (let i = 0; i < this.questionsCounter; i++) {
        if (this.questions[i].answer != null) {
          counter += 1;
        }
      }
      return counter;
    },
    stepperModel() {
      if (this.answeredCount < this.questionsCounter) {
        return 0;
      } else if (this.accessTokenState !== TOKEN_STATE_USED) {
        return 1;
      } else {
        return 2;
      }
    },
  },
  watch: {},
  created() {
    this.session = useSessionStore();
  },
  mounted() {
    this.accessTokenState = this.session.tokenState;
  },
  methods: {
    onDialogSuccess() {
      this.session.$patch({
        tokenState: TOKEN_STATE_USED,
      });
      this.accessTokenState = TOKEN_STATE_USED;
    },
  },
};
</script>
