<template>
  <div>
    <EvaluationHeadline
      title="Teilnehmer-Vergleich"
      page="2"
      :next="'/auswertung/3/'"
      :prev="'/auswertung/1/'"
    />
    <v-container>
      <v-row>
        <v-col cols="12" lg="5">
          <main-card>
            <HeadlineSmall title="Vergleich mit anderen Teilnehmern" />
            <p>
              In dieser Tabelle sehen Sie, wie gut Ihr Lernverhalten im
              Vergleich zu früher befragten Personen in der Vergleichsgruppe
              ist.
            </p>

            <p>
              Dazu vergleichen Sie den erreichten Wert in jeder Kategorie mit
              den Werten dieser Vergleichsgruppe.
            </p>
          </main-card>
        </v-col>
        <v-col cols="12" lg="7">
          <main-card>
            <HeadlineSmall title="Prozentrang" />
            <p v-if="isHochschule" class="">
              Zu jeder erreichten Zahl finden Sie links in der Tabelle einen
              Prozentrang. Dieser sagt, wie viel Prozent der Befragten Ihre
              Punktzahl oder weniger erreicht haben. Haben Sie den Test im
              Bereich Hochschule gewählt, so vergleichen Sie sich mit 1.000
              Befragten, im Bereich Schule mit 1.000 befragten Testteilnehmern.
              Je höher der Prozentrang ist, umso besser ist Ihr erzielter Wert
              im Vergleich zum Durchschnitt dieser 1.000 Befragten.
            </p>
            <p v-else class="">
              Zu jeder erreichten Zahl finden Sie links in der Tabelle einen
              Prozentrang. Dieser sagt, wie viel Prozent der Befragten Ihre
              Punktzahl oder weniger erreicht haben. Haben Sie den Test im
              Bereich Hochschule gewählt, so vergleichen Sie sich mit 1.300
              Befragten, im Bereich Schule mit 1.300 befragten Testteilnehmern.
              Je höher der Prozentrang ist, umso besser ist Ihr erzielter Wert
              im Vergleich zum Durchschnitt dieser 1.300 Befragten.
            </p>
            <p class="">
              Für jede der neun Kategorien lesen Sie also wie folgt: X Prozent
              der Befragten haben Y Punkte, also gleich viele Punkte wie ich
              oder weniger erreicht.
            </p>
          </main-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-title>
                Definitionen der Kategorien einblenden
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <category-overview :items="categories" />
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col>
          <main-card>
            <h2>Auflistung der Kategorien und Punkte</h2>
            <v-divider class="mt-1 mb-8" />
            <HistoryCompareTable />
          </main-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <main-card>
            <HeadlineSmall title="Beispiel" />
            <p class="">
              Wenn Sie in der Kategorie Haltung 29 Punkte erreicht haben, dann
              liegen Sie genau in der Mitte, denn 50 Prozent der
              Vergleichsgruppe haben 29 Punkte oder weniger erreicht.
            </p>
            <p>
              Mit 28 Punkten bei der Kategorie Selbstkontrolle gehören Sie zu
              den besten 25 Prozent der Vergleichsgruppe, denn 75 Prozent haben
              28 Punkte oder weniger erreicht.
            </p>
            <p>
              Mit 26 Punkten bei der Kategorie Wesentliches erkennen gehören Sie
              zu jenen 20 Prozent, die 26 Punkte oder weniger erreichten.
            </p>
            <p>
              Sollten Sie eine Punktzahl erreicht haben, die von keinem
              Teilnehmer der Vergleichsgruppe erreicht wurde, springt die
              Anzeige auf den nächstniedrigeren Wert.
            </p>
          </main-card>
        </v-col>
      </v-row>

      <div class="mt-10 text-center wli-dashed-border-top py-4">
        <Button :x-large="true" :to="'/auswertung/3/'" :button-text="'Seite 3'">
          <template #icon_right>
            <font-awesome-icon
              icon="fa-solid fa-chevron-right"
              size="lg"
              :class="'ms-3'"
            />
          </template>
        </Button>
      </div>
    </v-container>
  </div>
</template>

<script>
import EvaluationHeadline from "./../components/evaluation/EvaluationHeadline";
import HeadlineSmall from "./../components/layout/HeadlineSmall";
import HistoryCompareTable from "./../components/evaluation/HistoryCompareTable";
import authPageMixin from "../mixins/authPageMixin";
import MainCard from "./../components/layout/MainCard";
import CategoryOverview from "@/components/evaluation/CategoryOverview";
import Button from "@/components/layout/Button";
import { useSessionStore } from "@/stores/session";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
const POOL_ID_HOCHSCHULE = 1;
export default {
  name: "EvaluationTwo",
  components: {
    Button,
    EvaluationHeadline,
    HeadlineSmall,
    HistoryCompareTable,
    MainCard,
    CategoryOverview,
    FontAwesomeIcon,
  },
  mixins: [authPageMixin],
  data: () => ({
    categories: [],
    poolId: null,
    session: null,
  }),
  computed: {
    isHochschule() {
      if (this.poolId == POOL_ID_HOCHSCHULE) {
        return true;
      }
      return false;
    },
  },
  created() {
    this.session = useSessionStore();
  },
  mounted() {
    this.fetch_categories();
    this.poolId = this.session.poolId;
  },
  methods: {
    fetch_categories() {
      axios
        .get("questions/categories/", {
          headers: this.getAuthHeader(),
        })
        .then((response) => {
          // Update the categories property with the returned data
          this.categories = response.data;
        })
        .catch((error) => {
          // Log the error details
          console.log(error.response ? error.response.data : error);
        });
    },
  },
};
</script>
<style></style>
