<template>
  <v-app>
    <AppBar />
    <v-main id="" class="pb-6" :style="getBackground()">
      <router-view />
    </v-main>

    <Footer />
  </v-app>
</template>

<script>
import AppBar from "@/components/layout/AppBar";
import Footer from "@/components/layout/Footer";

export default {
  name: "App",

  components: {
    AppBar,
    Footer,
  },

  data: () => ({}),
  methods: {
    getBackground() {
      return "background-color: #edf7ff;";
    },
  },
};
</script>

<style lang="scss">
.colordiv {
  width: 50px;
  height: 50px;
  border: 1px solid grey;
  cursor: pointer;
}
.v-btn {
  text-transform: none;
}

p,
li {
  font-size: 17px;
}
li {
  margin-top: 5px;
}
#main-div {
  background-color: #ff5252;
  background-color: #edf7ff;
  background-color: #82b1ff;
  background-color: #90caf9;
  background-color: #448aff;
  //background-color: #2979ff;
}
</style>
