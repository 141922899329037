<template>
  <div>
    <v-container class="mt-5">
      <div class="page-headline">
        <v-row>
          <v-col cols="12">
            <main-card>
              <v-row>
                <v-col cols="12" md="10">
                  <h1 :class="classStr">PDF Export</h1>
                </v-col>
                <v-col cols="12" md="2" class="pt-3 text-center">
                  <BackButton />
                </v-col>
              </v-row>
            </main-card>
          </v-col>
        </v-row>
      </div>
      <v-row>
        <v-col cols="12">
          <main-card>
            <HeadlineSmall :title="'Beschreibung'" />
            <p class="">
              Sie können sich die verschiedenen Varianten der Auswertung als PDF
              auf Ihrem Endgerät abspeichern. Sie erhalten sowohl alle
              Auswertungstabellen als auch eine Liste der beantworteten Fragen
              inkl. Ihrer Antworten, die bereits nach Kategorien sortiert sind.
            </p>
          </main-card>
        </v-col>
      </v-row>
      <div class="text-center py-4">
        <Button
          v-if="!isDownloading"
          :x-large="true"
          :button-text="'PDF Export'"
          @click="getPdf()"
        >
          <template #icon_right>
            <font-awesome-icon
              icon="fa-solid fa-file-pdf"
              class="ps-3"
              size="lg"
            />
          </template>
        </Button>
        <v-progress-circular
          v-if="isDownloading"
          indeterminate
          color="primary"
        />
      </div>
    </v-container>
  </div>
</template>

<script>
import authPageMixin from "../mixins/authPageMixin";
import HeadlineSmall from "@/components/layout/HeadlineSmall";
import MainCard from "@/components/layout/MainCard";
import BackButton from "@/components/layout/BackButton";
import download from "downloadjs";
import Button from "@/components/layout/Button.vue";
import { useSessionStore } from "@/stores/session";
import axios from "axios";

export default {
  name: "EvaluationPdfExport",
  components: {
    BackButton,
    MainCard,
    HeadlineSmall,
    Button,
  },
  mixins: [authPageMixin],
  data: () => ({
    isDownloading: false,
    session: null,
  }),
  computed: {
    classStr() {
      if (this.windowInnerWith < 960) {
        return "  text-center";
      }
      return " ";
    },
    windowInnerWith() {
      return window.innerWidth;
    },
  },
  created() {
    this.session = useSessionStore();
  },
  mounted() {},
  methods: {
    getPdf() {
      this.isDownloading = true;
      let url = "evaluation/evaluation-pdf/?hash=" + this.session.accessToken;
      axios
        .get(url, {
          headers: this.getAuthHeader(),
          responseType: "blob",
        })
        .then((response) => {
          this.isDownloading = false;
          const content = response.headers["content-type"];
          download(response.data, "wli-test-auswertung.pdf", content);
        })
        .catch((error) => {
          this.isDownloading = false;
          console.log(error.response ? error.response.data : error);
        });
    },
  },
};
</script>
<style>
.pdf-export {
  max-width: 750px;
  overflow-x: hidden;
}
</style>
