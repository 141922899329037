import { useSessionStore } from "@/stores/session";

let authPageMixin = {
  data: () => ({
    session: null,
  }),
  created() {
    this.session = useSessionStore();
    if (!this.session.isActive) {
      if (
        this.$router.currentRoute.value.path !== "/" &&
        this.$router.currentRoute.value.path !== "/anleitung/"
      ) {
        this.$router.push("/");
      }
    }
  },
  methods: {
    getAuthHeader() {
      return {
        Authorization: "Token " + this.session.accessToken,
      };
    },
    getAuthHeaderCompareToken() {
      return {
        Authorization: "Token " + this.session.compareHash,
      };
    },
  },
};

export default authPageMixin;
