<template>
  <span>
    <Button
      v-if="isLoggedIn"
      :color="'primary'"
      :button-text="'Ausloggen'"
      :variant="'outlined'"
      large
      @click="logout()"
    >
      <template #icon_left
        ><font-awesome-icon icon="fas fa-sign-out-alt" class="me-2"
      /></template>
    </Button>
    <v-dialog v-else max-width="600">
      <template #activator="{ props }">
        <v-btn
          v-bind="props"
          color="primary"
          class="mr-3 text-none"
          rounded
          :outlined="outlined"
          size="large"
        >
          <font-awesome-icon icon="fas fa-sign-in-alt" class="me-2" />
          {{ buttonText }}
        </v-btn>
      </template>
      <main-card>
        <LoginForm />
      </main-card>
    </v-dialog>
  </span>
</template>

<script>
import authControlMixin from "../../mixins/authControlMixin";
import LoginForm from "@/components/participants/LoginForm";
import MainCard from "@/components/layout/MainCard";
import Button from "@/components/layout/Button";
export default {
  components: { Button, MainCard, LoginForm },
  mixins: [authControlMixin],
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    outlined: {
      type: Boolean,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped></style>
