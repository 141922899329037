<template>
  <div>
    <CompareHeadline
      title="Vergleich zweier Tests"
      page="2"
      :next="'/vergleich/3/'"
      :prev="'/vergleich/1/'"
    />
    <v-container>
      <v-row class="">
        <v-col cols="12" md="5">
          <main-card>
            <HeadlineSmall :title="'Anleitung'" />
            <p class="">
              An dieser Stelle können Sie den aktuell durchgeführten Test mit
              einem vorherigen, bereits durchgeführten Test vergleichen. Sie
              benötigen dafür ausschließlich den Code des Tests, mit dem Sie das
              aktuelle Ergebnis vergleichen möchten. Den Code finden Sie auf dem
              PDF-Export.
            </p>
          </main-card>
        </v-col>
        <v-col cols="12" md="7">
          <main-card>
            <HeadlineSmall :title="'Token Eingabe für den Vergleich'" />
            <p class="">
              Den Code für den Vergleich kann man auf der ersten Vergleichsseite
              ändern.
            </p>
            <Button
              :button-text="'Seite 1'"
              :x-large="true"
              :to="'/vergleich/1/'"
            >
              <template #icon_left>
                <font-awesome-icon
                  icon="fa-solid fa-chevron-left"
                  size="lg"
                  :class="'me-3'"
                />
              </template>
            </Button>
          </main-card>
        </v-col>
      </v-row>

      <v-row class="">
        <v-col>
          <main-card>
            <h2>Auflistung der Kategorien und Punkte</h2>
            <v-divider class="mt-1 mb-8" />
            <CategoryPointsTable :are-two-tokens="true" />
          </main-card>
        </v-col>
      </v-row>

      <div class="mt-10 text-center wli-dashed-border-top py-4">
        <Button :x-large="true" :to="'/vergleich/3/'" :button-text="'Seite 3'">
          <template #icon_right>
            <font-awesome-icon
              icon="fa-solid fa-chevron-right"
              size="lg"
              :class="'ms-3'"
            />
          </template>
        </Button>
      </div>
    </v-container>
  </div>
</template>

<script>
import CompareHeadline from "./../components/compare/CompareHeadline";
import authPageMixin from "../mixins/authPageMixin";
import compareTokenMixin from "@/mixins/compareTokenMixin";
import CategoryPointsTable from "./../components/evaluation/CategoryPointsTable";
import MainCard from "./../components/layout/MainCard";
import HeadlineSmall from "@/components/layout/HeadlineSmall";
import Button from "@/components/layout/Button";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "CompareTwo",
  components: {
    Button,
    CompareHeadline,
    CategoryPointsTable,
    MainCard,
    HeadlineSmall,
  },
  mixins: [authPageMixin, compareTokenMixin],
  data: () => ({}),
  mounted() {},
  methods: {},
};
</script>
<style>
.wli-dashed-border-top {
  border-top: 2px dashed #fff;
}
.evaluation-card {
  width: 100%;
}
</style>
