<template>
  <div>
    <Headline title="Anleitung für den WLI-Test" />
    <v-container>
      <v-row>
        <v-col cols="12">
          <main-card>
            <HeadlineSmall title="Hilfe und Support" />
            <p>
              Bei Anregungen, Fragen oder Feedback:
              <a class="text-primary" href="mailto:verlag@cornelsen.ch"
                >verlag@cornelsen.ch</a
              >
            </p>
            <p>
              Bei Problemen der Webanwendung:
              <a class="text-primary" href="mailto:verlag@cornelsen.ch"
                >verlag@cornelsen.ch</a
              >
            </p>
            <h2>
              Anbieterin im Sinne des § 55 Absatz 1 RStV und § 5 Absatz 1 TMG
            </h2>
            <v-divider class="mt-1 mb-4" />
            <p>
              Cornelsen Verlag GmbH
              <br />
              Mecklenburgische Straße 53
              <br />
              14197 Berlin
              <br />
              Postanschrift: 14328 Berlin
            </p>
            <p>
              Telefon: +49 30 897 85 0
              <br />
              Telefax: +49 30 897 85 499
              <br />
              E-Mail:
              <a class="text-primary" href="mailto:service@cornelsen.de"
                >service@cornelsen.de</a
              >
            </p>
            <p>
              Umsatzsteuer-ID-Nr. DE 136665967/ILN: 4032357100009
              <br class="mb-2" />
              Sitz Berlin, AG Charlottenburg, HRB 114796 B
            </p>
            <HeadlineSmall title="Geschäftsführer" />
            <p>
              Meeuwis van Arkel
              <br />
              Martina Fiddrich
              <br />
              Christine Hauck
              <br />
              Silvia Jakob
              <br />
              Mark van Mierle (Vorsitz)
              <br />
              Georg Müller-Loeffelholz
            </p>
            <HeadlineSmall title="Verantwortlicher im Sinne des § 18 MStV" />
            <p>
              Mark van Mierle, Geschäftsführer der Cornelsen Verlag GmbH,
              Anschrift siehe oben.
            </p>
            <h2>Inhalte</h2>
            <v-divider class="mt-1 mb-4" />

            <p>
              Der Anbieter behält sich eine Nutzung der Inhalte für Text und
              Data Mining im Sinne § 44b UrhG ausdrücklich vor.
            </p>

            <p>
              Der Fragebogen Wie lerne ich? wurde aus der Originalfassung LASSI
            </p>
            <p>
              Learning and Study Strategies Inventory<br />
              H&H Publishing Company Inc.<br />
              (2002): 1231 Kapp Drive, Clearwater<br />
              Florida 33765
            </p>
            <p>abgeleitet.</p>
            <p>
              Zu diesem Fragebogen ist das Fachbuch Lern- und Arbeitsstrategien
              ISBN: 978-3-06-451614-4 erhältlich.
            </p>
            <HeadlineSmall title="Autoren" />
            <p>
              Claire Ellen Weinstein, David R. Palmer, Anne C. Schulte und
              Christoph Metzger
            </p>
            <p>
              Deutsche Übersetzung und inhaltliche Anpassung: Christoph Metzger
            </p>

            <HeadlineSmall title="Redaktion" />
            <p>Julia Baum</p>
            <HeadlineSmall title="Layout" />
            <p>Stefan Lesche, Michael Rydzek</p>
            <HeadlineSmall title="Technische Umsetzung" />
            <p>Edu-Werkstatt Berlin</p>

            <HeadlineSmall title="WLI Hochschule " />
            <p>
              © 2002 (Originalfassung) H&amp;H Publishing Company Inc, 1231
              Kapp Drive, Clearwater, Florida 33765
            </p>
            <p>
              © 2008 (Lizenzausgabe) Sauerländer Verlage AG, Oberentfelden,
              Schweiz
            </p>
            <p>© 2013 (Lizenzausgabe) Cornelsen Schulverlage GmbH, Berlin</p>
            <p>© 2017 (Lizenzausgabe) Cornelsen Verlag GmbH, Berlin</p>
            <HeadlineSmall title="WLI Schule " />

            <p>
              Claire Ellen Weinstein, David R. Palmer, Christoph Metzger (2010)
              LAS-HS, Learning and Study Strategies Inventory – High School,
              German Version:
            </p>
            <p>
              © 1990, H&H Publishing Company, Inc., 1231 Kapp Drive,
              Clearwater, Florida 3376
            </p>
            <h2>Beschwerden/Streitschlichtung</h2>
            <v-divider class="mt-1 mb-4" />

            <p>
              Die EU-Kommission stellt eine Plattform für außergerichtliche
              Streitschlichtung bereit. Verbrauchern gibt dies die Möglichkeit,
              Streitigkeiten im Zusammenhang mit Ihrer Online-Bestellung
              zunächst ohne die Einschaltung eines Gerichts zu klären. Die
              Streitbeilegungs-Plattform ist unter dem externen Link
              <a class="text-primary" href="https://ec.europa.eu/consumers/odr">
                https://ec.europa.eu/consumers/odr
              </a>
              erreichbar. Wir nehmen an einem Streitbeilegungsverfahren vor
              einer Verbraucherschlichtungsstelle nicht teil.
            </p>
            <p>
              Unsere E-Mail-Adresse lautet:
              <a class="text-primary" href="mailto:service@cornelsen.de"
                >service@cornelsen.de</a
              >
            </p>
          </main-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Headline from "./../components/layout/Headline";
import HeadlineSmall from "./../components/layout/HeadlineSmall";
import MainCard from "@/components/layout/MainCard";
export default {
  name: "Impressum",
  components: { MainCard, Headline, HeadlineSmall },
};
</script>

<style>
p.bigger-font-size {
  font-size: 120%;
}
h2.sub-headline-impressum {
  font-size: 200%;
  color: #0064aa;
  font-weight: bolder;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}
</style>
