<template>
  <div class="page-headline">
    <v-container class="mt-5">
      <v-row>
        <v-col cols="12">
          <main-card>
            <h1 :class="classStr">
              {{ title }}
            </h1>
          </main-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import MainCard from "@/components/layout/MainCard";
export default {
  name: "Headline",
  components: { MainCard },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data: () => ({}),
  computed: {
    classStr() {
      if (this.windowInnerWith < 960) {
        return "  text-center";
      }
      return " ";
    },
    windowInnerWith() {
      return window.innerWidth;
    },
  },
};
</script>

<style>
.wli-dashed-border-bottom {
  border-bottom: 2px dashed #111;
}
</style>
