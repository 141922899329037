<template>
  <div>
    <EvaluationHeadline
      title="Verbesserungs-Tipps"
      page="3"
      :next="null"
      :prev="'/auswertung/2/'"
    />
    <v-container>
      <v-row>
        <v-col cols="12">
          <main-card>
            <HeadlineSmall title="PDF-Export" />
            <p class="">
              Sie haben sich Ihr Ergebnis / Ihren Vergleich zweier Tests als PDF
              auf Ihrem Endgerät abgespeichert? Wunderbar – Sie sind fertig!
            </p>
          </main-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <main-card>
            <HeadlineSmall title="Was tue ich dann?" />
            <p class="">
              Was unternehme ich, um mein Lernverhalten zu verbessern? Notieren
              Sie sich Massnahmen, mit denen Sie Ihr Lernverhalten verbessern
              wollen. Eine erste Hilfe dazu kann sein: Stellen Sie für jene
              Kategorien, in denen Sie sich verbessern wollen, alle Aussagen
              zusammen, bei denen Sie 4 oder weniger Punkte erreicht haben, und
              überlegen Sie sich, was Sie tun können. Eine Auflistung der Fragen
              je Kategorie inkl. Ihrer Antworten finden Sie in dem
              Auswertungs-PDF, dass Sie sich abgespeichert haben.
            </p>
            <p>
              Weitere Unterstützung bekommen Sie durch auf dem Test basierende
              Literatur, die im Shop von Cornelsen Schweiz erhältlich sind:
              <a
                href="https://www.cornelsen.ch/Service/WLI-Unser-Selbsttest-zum-Lernverhalten-wird-digital"
                target="_blank"
                class="text-primary"
                >Zum Webshop</a
              >
            </p>
          </main-card>
        </v-col>
      </v-row>

      <div class="mt-10 text-center wli-dashed-border-top py-4">
        <Button
          :x-large="true"
          :to="'/auswertung/export/'"
          :button-text="'PDF Export'"
        >
          <template #icon_right>
            <font-awesome-icon
              icon="fa-solid fa-file-pdf"
              class="ps-3"
              size="lg"
            />
          </template>
        </Button>
      </div>
    </v-container>
  </div>
</template>

<script>
import EvaluationHeadline from "./../components/evaluation/EvaluationHeadline";
import HeadlineSmall from "./../components/layout/HeadlineSmall";
import MainCard from "./../components/layout/MainCard";
import Button from "@/components/layout/Button";
import authPageMixin from "@/mixins/authPageMixin";

export default {
  name: "EvaluationThree",
  components: {
    Button,
    EvaluationHeadline,
    HeadlineSmall,
    MainCard,
  },
  mixins: [authPageMixin],
  data: () => ({
    categories: [],
    pool_contents: [],
  }),
  mounted() {},
  methods: {},
};
</script>
<style></style>
