<template>
  <div class="">
    <v-avatar color="primary" size="38">
      <span class="text-white text-h6">{{ number }}</span>
    </v-avatar>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: Number,
      required: true,
    },
  },
};
</script>
