import { useSessionStore } from "@/stores/session";

let compareTokenMixin = {
  data: () => ({
    session: null,
  }),
  created() {
    this.session = useSessionStore();
    if (this.session.isActive) {
      if (!this.session.compareToken) {
        this.$router.push("/vergleich/1");
      }
    }
  },
  methods: {},
};

export default compareTokenMixin;
