<template>
  <div>
    <v-container>
      <v-row class="mt-5">
        <v-col cols="12" class="">
          <main-card>
            <h1 class="pb-3">Wie lerne ich?</h1>
            <p>
              Das Lernen begleitet uns ein Leben lang. Haben Sie sich jedoch
              schon einmal die Frage gestellt, wie Sie lernen?
            </p>
            <p>
              Viel wichtiger als der Inhalt unseres Lernens ist die angewandte
              Technik. Es gibt diverse Lerntypen; einige nehmen Information am
              effektivsten über das Gehör auf, andere können ihr Wissen durch
              das Aufschreiben verinnerlichen.
            </p>
          </main-card>
        </v-col>
        <v-col cols="12" lg="6" class="">
          <main-card>
            <p>
              Ihre künftigen Erfolge in Schule, Studium und Beruf hängen davon
              ab, wie Sie verschiedene Lerntechniken einsetzen. Mit diesem Test
              können Sie herausfinden, wie Sie persönlich am besten lernen und
              somit künftig die Zeit gezielt nutzen, um Ihr vollständiges
              Potenzial auszuschöpfen.
            </p>
            <p>
              Mit diesem Test schätzen Sie Ihr eigenes Lernverhalten ein und
              erhalten sogleich die entsprechende differenzierte Auswertung.
              Diese Erkenntnisse zeigen Ihnen Ihre persönlichen
              Verbesserungsmöglichkeiten auf, womit Sie Ihr Lernen optimieren
              können und somit erfolgreicher werden.
            </p>
            <p>
              In drei Schritten zur personalisierten Antwort auf die Frage: „Wie
              lerne ich?“:
            </p>
            <ol class="ms-7 mb-5">
              <li class="bold-font-weight">Test starten</li>
              <li class="bold-font-weight">Test durchführen</li>
              <li class="bold-font-weight">Auswertung als Download erhalten</li>
            </ol>
            <p>
              Drücken Sie den Button "Zugangscode eingeben". Jetzt können Sie
              starten!
            </p>
            <p>
              Sie haben noch keinen Code? Dann besuchen Sie den Onlineshop von
              Cornelsen Schweiz.
              <br />
              <a
                :href="'https://www.cornelsen.ch/Service/WLI-Unser-Fragebogen-wird-digital'"
                target="_blank"
                class="text-primary"
              >
                Zum Onlineshop
              </a>
            </p>
            <LoginDialogButtonVue :button-text="'Test starten'" />
            <Button
              :to="'/anleitung/'"
              :large="true"
              :button-text="'Anleitung'"
            />
          </main-card>
        </v-col>
        <v-col cols="12" lg="6">
          <v-card class="pa-10" height="100%">
            <h3>Vorschau</h3>
            <v-divider class="mt-1 mb-10" />
            <v-carousel cycle hide-delimiters class="">
              <v-carousel-item
                v-for="(item, i) in sliderItems"
                :key="i"
                :src="item.src"
                class=""
                reverse-transition="fade-transition"
                transition="fade-transition"
              />
            </v-carousel>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import LoginDialogButtonVue from "@/components/participants/LoginDialogButton";
import MainCard from "@/components/layout/MainCard";
import Button from "@/components/layout/Button";

export default {
  name: "Index",
  components: {
    Button,
    MainCard,
    LoginDialogButtonVue,
  },
  data: () => ({
    participant: null,
    sliderItems: [
      { src: require("../assets/wli-start-bild.jpg") },
      { src: require("../assets/fragen-1-6.jpg") },
      { src: require("../assets/gesamtpunkte.jpg") },
      { src: require("../assets/teilnehmer-vergleich.jpg") },
    ],
  }),

  created() {
    // get return url from route parameters or default to '/'
    //this.returnUrl = this.$route.query.returnUrl || "/";
  },
};
</script>
<style>
p.bigger-font-size {
  font-size: 120%;
}
li.bold-font-weight {
  font-weight: bold;
}
</style>
