<template>
  <div>
    <CompareHeadline
      title="Vergleich zweier Tests"
      page="3"
      :next="null"
      :prev="'/vergleich/2/'"
    />
    <v-container>
      <v-row class="">
        <v-col cols="12" md="5">
          <main-card>
            <HeadlineSmall :title="'Anleitung'" />
            <p class="text-body-1">
              An dieser Stelle können Sie den aktuell durchgeführten Test mit
              einem vorherigen, bereits durchgeführten Test vergleichen. Sie
              benötigen dafür ausschließlich den Code des Tests, mit dem Sie das
              aktuelle Ergebnis vergleichen möchten. Den Code finden Sie auf dem
              PDF-Export.
            </p>
          </main-card>
        </v-col>
        <v-col cols="12" md="7">
          <main-card>
            <HeadlineSmall :title="'Token Eingabe für den Vergleich'" />
            <p class="">
              Den Code für den Vergleich kann man auf der ersten Vergleichsseite
              ändern.
            </p>
            <Button
              :button-text="'Seite 1'"
              :x-large="true"
              :to="'/vergleich/1/'"
            >
              <template #icon_left>
                <font-awesome-icon
                  icon="fa-solid fa-chevron-left"
                  size="lg"
                  :class="'me-3'"
                />
              </template>
            </Button>
          </main-card>
        </v-col>
      </v-row>

      <v-row class="">
        <v-col>
          <main-card>
            <h2>
              Auflistung der Kategorien und Punkte
              <v-chip color="primary" class="ml-2"> Aktuelles Ergebnis </v-chip>
              <v-chip color="brown-darken-2" class="ml-2">
                Vorheriges Ergebnis
              </v-chip>
              <v-chip color="purple-darken-2" class="ml-2">
                Gleiches Ergebnis
              </v-chip>
            </h2>
            <v-divider class="mt-1 mb-8" />
            <HistoryCompareTable :are-two-tokens="true" />
          </main-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CompareHeadline from "./../components/compare/CompareHeadline";
import authPageMixin from "../mixins/authPageMixin";
import compareTokenMixin from "@/mixins/compareTokenMixin";
import HistoryCompareTable from "./../components/evaluation/HistoryCompareTable";
import MainCard from "./../components/layout/MainCard";
import HeadlineSmall from "@/components/layout/HeadlineSmall";
import Button from "@/components/layout/Button";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "CompareTwo",
  components: {
    Button,
    CompareHeadline,
    HistoryCompareTable,
    MainCard,
    HeadlineSmall,
  },
  mixins: [authPageMixin, compareTokenMixin],
  data: () => ({}),
  mounted() {},
  methods: {},
};
</script>
<style>
.wli-dashed-border-top {
  border-top: 2px dashed #fff;
}
.evaluation-card {
  width: 100%;
}
</style>
