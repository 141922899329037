<template>
  <div id="category-points-table">
    <v-progress-circular
      v-if="isLoading"
      indeterminate
      :size="50"
      color="primary"
      justify="center"
    />
    <div
      v-for="(item, cindex) in categories"
      v-else
      :key="cindex"
      class="evaluation-row my-5"
    >
      <div class="evaluation-title text-right pr-5 pt-2">
        <h4>
          {{ item.title }}
          <v-tooltip location="top" max-width="250">
            <template #activator="{ props }">
              <font-awesome-icon
                v-bind="props"
                icon="fa-solid fa-circle-info"
                :class="'text-primary'"
                size="xl"
              />
            </template>
            <span>{{ item.description }}</span>
          </v-tooltip>
        </h4>
      </div>
      <div class="evaluation-pointscol">
        <div
          v-for="(question, qindex) in item.questions"
          :key="qindex"
          class="evaluation-question-col"
        >
          <div>
            <div v-if="!areTwoTokens" class="evaluation-question-points">
              {{ question.participant_points }}
            </div>
            <div v-else class="evaluation-question-points compare-padding">
              {{ getComparePoints(cindex, question.position) }} /
              {{ question.participant_points }}
            </div>
            <div>
              <p class="text-body-2 text-center question-link">
                <a :href="questionLink(question.position)" class="text-primary"
                  >#{{ question.position }}</a
                >
              </p>
            </div>
          </div>
          <div class="evaluation-question-sign">
            <p v-if="qindex < item.questions.length - 1">+</p>
            <p v-else>=</p>
          </div>
        </div>
        <div>
          <div
            v-if="!areTwoTokens"
            class="evaluation-question-total font-weight-bold"
          >
            {{ item.participant_points }}
          </div>
          <div
            v-else
            class="evaluation-question-total compare-padding font-weight-bold"
          >
            {{ getCompareTotalPoints(cindex) }} /
            {{ item.participant_points }}
          </div>
        </div>
        <div class="pl-10 pt-2">
          <v-tooltip
            v-if="item.extension_proposals.length !== 0"
            location="end"
            max-width="320"
            content-class="custom-tooltip"
          >
            <template #activator="{ props }">
              <img
                v-bind="props"
                :src="getCupFilename(item.participant_points)"
                class="evaluation-table-cup"
                alt="evaluation_table_cup"
              />
              <font-awesome-icon
                v-bind="props"
                icon="fa-solid fa-circle-info"
                :class="'text-' + getTooltipColor(item.participant_points)"
                size="lg"
              />
            </template>
            <div>
              <p class="mb-3">
                <strong>WLI – VORSCHLAG FÜR ERWEITERUNG</strong>
              </p>
              <p class="mb-2">
                <strong>{{ item.title }}</strong>
              </p>
              <ul>
                <li
                  v-for="(proposal, index) in item.extension_proposals"
                  :key="index"
                  :class="'ms-5'"
                >
                  <u>{{ proposal.title }}</u
                  >: {{ proposal.description }}
                </li>
              </ul>
            </div>
          </v-tooltip>
          <img
            v-else
            :src="getCupFilename(item.participant_points)"
            class="evaluation-table-cup"
            alt="evaluation_table_cup"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import authPageMixin from "../../mixins/authPageMixin";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "CategoryPointsTable",
  components: { FontAwesomeIcon },
  mixins: [authPageMixin],
  props: {
    areTwoTokens: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    isLoading: false,
    categories: [],
    compareCategories: [],
  }),
  mounted() {
    this.fetchCategories();
    if (this.areTwoTokens) {
      this.fetchCompareCategories();
    }
  },
  methods: {
    fetchCategories() {
      axios
        .get("questions/categories/", {
          headers: this.getAuthHeader(),
        })
        .then((response) => {
          this.categories = response.data;
        })
        .catch((error) => {
          console.log(error.response ? error.response.data : error);
        });
    },
    /** TODO: double code */
    fetchCompareCategories() {
      this.isLoading = true;
      axios
        .get("questions/categories/", {
          headers: this.getAuthHeaderCompareToken(),
        })
        .then((response) => {
          this.isLoading = false;
          this.compareCategories = response.data;
        })
        .catch((error) => {
          console.log(error.response ? error.response.data : error);
        });
    },
    getComparePoints(categoryIndex, position) {
      let question = this.compareCategories[categoryIndex]?.questions.find(
        (item) => item.position == position,
      );
      return question.participant_points;
    },
    getCompareTotalPoints(categoryIndex) {
      return this.compareCategories[categoryIndex].participant_points;
    },

    getTooltipColor(points) {
      if (points <= 23) {
        return "red-darken-2";
      } else if (points <= 31) {
        return "yellow-darken-3";
      } else {
        return "green";
      }
    },

    getCupFilename(points) {
      if (points <= 23) {
        return require("../../assets/Pokal_Icon_Rot.svg");
      } else if (points <= 31) {
        return require("../../assets/Pokal_Icon_Gelb.svg");
      } else {
        return require("../../assets/Pokal_Icon_Gruen.svg");
      }
    },
    questionLink(position) {
      return "/test/?question=" + position;
    },
  },
};
</script>
<style lang="scss">
.evaluation-row,
.evaluation-pointscol,
.evaluation-question-col {
  display: flex;
}
.evaluation-title {
  min-width: 240px;
}
.evaluation-table-cup {
  width: 40px;
  height: 40px;
}
.evaluation-question-points,
.evaluation-question-total {
  padding: 10px 20px;
  border: 2px solid #666;
  background-color: #e5e5e5;
}
.evaluation-question-total {
  padding: 10px 30px;
}
.evaluation-question-sign {
  padding: 10px 8px;
}
.compare-padding {
  padding: 10px !important;
}
.question-link a {
  font-size: 13px;
  text-decoration: none;
}

#category-points-table {
  overflow: scroll;
}

.custom-tooltip {
  background-color: #0168ef !important;
}
</style>
