let detectMobileMixin = {
  computed: {
    isMobile() {
      if (this.$screen.width < 960) {
        return true;
      }
      return false;
    },
  },
};

export default detectMobileMixin;
