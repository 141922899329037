import { defineStore } from "pinia";

export const useSessionStore = defineStore("session", {
  state: () => {
    return {
      accessToken: null,
      tokenState: null,
      participantId: null,
      poolId: null,
      compareToken: null,
      compareHash: null,
    };
  },
  getters: {
    isActive() {
      return this.accessToken !== null;
    },
  },
  actions: {
    setAccessToken(tokenHash, state, participantId, poolId) {
      this.accessToken = tokenHash;
      this.tokenState = state;
      this.participantId = participantId;
      this.poolId = poolId;
    },
    setCompareToken(compareToken, compareHash) {
      this.compareHash = compareHash;
      this.compareToken = compareToken;
    },
    resetCompareToken() {
      this.compareHash = null;
      this.compareToken = null;
    },
    setParticipantId(participantId) {
      this.participantId = participantId;
    },
  },
  persist: true,
});
