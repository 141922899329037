<template>
  <div>
    <CompareHeadline
      title="Vergleich zweier Tests"
      page="1"
      :next="nextPage()"
      :prev="null"
    />
    <v-container>
      <v-row class="">
        <v-col cols="12" md="5">
          <main-card>
            <HeadlineSmall :title="'Anleitung'" />
            <p class="">
              An dieser Stelle können Sie den aktuell durchgeführten Test mit
              einem vorherigen, bereits durchgeführten Test vergleichen. Sie
              benötigen dafür ausschließlich den Code des Tests, mit dem Sie das
              aktuelle Ergebnis vergleichen möchten. Den Code finden Sie auf dem
              PDF-Export.
            </p>
          </main-card>
        </v-col>
        <v-col cols="12" md="7">
          <main-card>
            <HeadlineSmall :title="'Code-Eingabe für den Vergleich'" />
            <SetCompareToken
              @validatedToken="isCompareTokenValid = true"
              @deletedToken="isCompareTokenValid = false"
            />
          </main-card>
        </v-col>
      </v-row>

      <div class="mt-10 text-center wli-dashed-border-top py-4">
        <Button
          v-if="isCompareTokenValid"
          :x-large="true"
          :to="'/vergleich/2/'"
          :button-text="'Seite 2'"
        >
          <template #icon_right>
            <font-awesome-icon
              icon="fa-solid fa-chevron-right"
              size="lg"
              :class="'ms-3'"
            />
          </template>
        </Button>
      </div>
    </v-container>
  </div>
</template>

<script>
import CompareHeadline from "./../components/compare/CompareHeadline";
import SetCompareToken from "./../components/compare/SetCompareToken.vue";
import authPageMixin from "../mixins/authPageMixin";
import HeadlineSmall from "@/components/layout/HeadlineSmall";
import MainCard from "@/components/layout/MainCard";
import Button from "@/components/layout/Button";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "CompareOne",
  components: {
    Button,
    MainCard,
    CompareHeadline,
    SetCompareToken,
    HeadlineSmall,
  },
  mixins: [authPageMixin],
  data: () => ({
    isCompareTokenValid: false,
  }),
  watch: {
    isCompareTokenValid() {
      this.nextPage();
    },
  },
  mounted() {},
  methods: {
    nextPage() {
      if (this.isCompareTokenValid === true) {
        return "/vergleich/2/";
      } else {
        return null;
      }
    },
  },
};
</script>
<style>
.wli-dashed-border-top {
  border-top: 2px dashed #fff;
}
.evaluation-card {
  width: 100%;
}
</style>
