<template>
  <div>
    <EvaluationHeadline
      title="Kategorie-Auswertung"
      page="1"
      :next="'/auswertung/2/'"
      :prev="null"
    />
    <v-container>
      <v-row class="">
        <v-col cols="12" md="5">
          <main-card>
            <HeadlineSmall :title="'Kategorie Analyse'" />
            <p class="">
              Jede der 72 Aussagen über das Lernverhalten ist einer von neun
              Kategorien zugewiesen. Jede dieser Kategorien stellt einen
              Gesichtspunkt zur Einschätzung Ihres Lernverhaltens dar.
            </p>
          </main-card>
        </v-col>
        <v-col cols="12" md="7">
          <main-card>
            <HeadlineSmall :title="'Wie wird gewertet?'" />
            <p>
              Bei der Auswertung Ihres Tests erscheint für jede Kategorie die
              erreichte Punktzahl. Maximal sind pro Kategorie 40 Punkte zu
              erreichen. Hier sehen Sie, wie gut Ihr Lernverhalten im Vergleich
              zum maximal möglichen Lernverhalten ist. Welche Konsequenzen zu
              ziehen sind, sehen Sie in der Legende.
            </p>
          </main-card>
        </v-col>
      </v-row>
      <v-row class="">
        <v-col>
          <main-card>
            <h2>Auflistung der Kategorien und Punkte</h2>
            <v-divider class="mt-1 mb-8" />
            <CategoryPointsTable :are-two-tokens="false" />
          </main-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <main-expansion-panel>
            <template #header> Legende </template>
            <template #content>
              <v-row>
                <v-col cols="12" md="4">
                  <p class="text-center">
                    <img
                      :src="require('../assets/Pokal_Icon_Gruen.svg')"
                      class="big-cup"
                    />
                  </p>
                  <p class="text-center">32 bis 40 Punkte = <b>hoch</b></p>
                  <p class="text-center">
                    Sie sollten Ihr Lernverhalten beibehalten.
                  </p>
                </v-col>
                <v-col cols="12" md="4">
                  <p class="text-center">
                    <img
                      :src="require('../assets/Pokal_Icon_Gelb.svg')"
                      class="big-cup"
                    />
                  </p>
                  <p class="text-center">24 bis 31 Punkte = <b>mittel</b></p>
                  <p class="text-center">
                    Sie sollten Ihr Lernverhalten kritisch überprüfen und
                    verbessern.
                  </p>
                </v-col>
                <v-col cols="12" md="4">
                  <p class="text-center">
                    <img
                      :src="require('../assets/Pokal_Icon_Rot.svg')"
                      class="big-cup"
                    />
                  </p>
                  <p class="text-center">8 bis 23 Punkte = <b>tief</b></p>
                  <p class="text-center">
                    Sie sollten dringend Verbesserungsmöglichkeiten suchen und
                    diese anwenden.
                  </p>
                </v-col>
              </v-row>
            </template>
          </main-expansion-panel>
        </v-col>
      </v-row>

      <div class="mt-10 text-center wli-dashed-border-top py-4">
        <Button :x-large="true" :to="'/auswertung/2/'" :button-text="'Seite 2'">
          <template #icon_right>
            <font-awesome-icon
              icon="fa-solid fa-chevron-right"
              size="lg"
              :class="'ms-3'"
            />
          </template>
        </Button>
      </div>
    </v-container>
  </div>
</template>

<script>
import EvaluationHeadline from "./../components/evaluation/EvaluationHeadline";
import CategoryPointsTable from "./../components/evaluation/CategoryPointsTable";
import authPageMixin from "../mixins/authPageMixin";
import MainCard from "./../components/layout/MainCard";
import MainExpansionPanel from "./../components/layout/MainExpansionPanel";
import HeadlineSmall from "@/components/layout/HeadlineSmall";
import Button from "@/components/layout/Button";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "EvaluationOne",
  components: {
    Button,
    EvaluationHeadline,
    CategoryPointsTable,
    MainCard,
    MainExpansionPanel,
    HeadlineSmall,
    FontAwesomeIcon,
  },
  mixins: [authPageMixin],
  data: () => ({
    categories: [],
  }),
  mounted() {
    this.fetch_categories();
  },
  methods: {
    fetch_categories() {
      axios
        .get("questions/categories/", {
          headers: this.getAuthHeader(),
        })
        .then((response) => {
          this.categories = response.data;
        })
        .catch((error) => {
          console.log(error.response ? error.response.data : error);
        });
    },
  },
};
</script>
<style>
.wli-dashed-border-top {
  border-top: 2px dashed #aaa;
}
.evaluation-card {
  width: 100%;
}
.big-cup {
  height: 100px;
}
</style>
