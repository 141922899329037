import axios from "axios";

let fetchResultsMixin = {
  data: () => ({
    result: [],
    compareResult: [],
  }),
  methods: {
    fetchResult() {
      axios
        .get("questions/categories/", {
          headers: this.getAuthHeader(),
        })
        .then((response) => {
          this.result = response.data;
        })
        .catch((error) => {
          console.log(error.response ? error.response.data : error);
        });
    },
    fetchCompareResult() {
      axios
        .get("questions/categories/", {
          headers: this.getAuthHeaderCompareToken(),
        })
        .then((response) => {
          this.compareResult = response.data;
        })
        .catch((error) => {
          console.log(error.response ? error.response.data : error);
        });
    },
    initResults() {
      this.fetchResult();
      if (this.areTwoTokens) {
        this.fetchCompareResult();
      }
    },
  },
};

export default fetchResultsMixin;
