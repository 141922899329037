<template>
  <div>
    <div v-if="!isLoggedIn">
      <HeadlineSmall :title="'Login'" />
      <v-form ref="loginForm" @submit.prevent="login">
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-text-field
              v-model="activationCode"
              :error-messages="errorMessage"
              hide-details="auto"
              label="Geben Sie hier ihren Freischaltcode ein..."
              required
            />
            <label hidden>
              Nicht ausfüllen, wenn sie ein menschliches Wesen sind.
              <input
                id="id_name"
                v-model="username"
                type="hidden"
                class="d-none"
              />
            </label>
          </v-col>
          <p />
          <v-col cols="12">
            <Button
              type="submit"
              :button-text="'Anmelden'"
              :x-large="true"
              @click="$emit('closeDialog')"
            />
          </v-col>
        </v-row>
      </v-form>
    </div>
    <div v-else>
      <HeadlineSmall :title="'Logout'" />
      <p>Sie sind aktuell angemeldet, möchten Sie sich abmelden?</p>
      <Button
        :x-large="true"
        :button-text="'Logout'"
        class="mt-3"
        @click="logout()"
      />
    </div>
  </div>
</template>

<script>
import authControlMixin from "../../mixins/authControlMixin";
import HeadlineSmall from "@/components/layout/HeadlineSmall";
import Button from "@/components/layout/Button";
import { useSessionStore } from "@/stores/session";
import axios from "axios";

export default {
  components: { Button, HeadlineSmall },
  mixins: [authControlMixin],
  emits: ["closeDialog"],
  data: () => ({
    activationCode: "",
    errorMessage: "",
    username: "",
    session: null,
  }),
  computed: {},
  created() {
    this.session = useSessionStore();
  },
  methods: {
    login() {
      axios
        .post("participants/login/", {
          access_token: this.activationCode,
          username: this.username,
        })
        .then((response) => {
          this.session.setAccessToken(
            response.data.token_hash,
            response.data.state,
            response.data.participant_id,
            response.data.pool_id,
          );
          this.$router.push("/anleitung/");
        })
        .catch((err) => {
          console.log(err);
          this.errorMessage = "Dieser Zugangscode ist uns nicht bekannt. ";
        });
    },
  },
};
</script>

<style scoped lang="scss"></style>
