<template>
  <div class="mt-10 px-10 py-3">
    <v-progress-linear
      color="green-darken-1"
      height="10"
      :model-value="percentValue"
    />
    <p class="text-center mt-3 text-grey-darken-3 fortschritt-text">
      {{ answeredCount }} von {{ questionsCounter }} Fragen beantwortet
    </p>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    questions: {
      type: Array,
      required: true,
    },
    questionsCounter: {
      type: Number,
      required: true,
    },
    answeredCount: {
      type: Number,
      required: true,
    },
  },
  data: () => ({}),
  computed: {
    percentValue() {
      return (this.answeredCount / this.questionsCounter) * 100;
    },
  },
};
</script>
<style>
.fortschritt-text {
  font-size: 22px;
}
</style>
