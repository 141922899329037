<template>
  <v-list-item>
    <v-list-item-title v-if="isLoggedIn" @click="logout()">
      Ausloggen
      <font-awesome-icon icon="fas fa-sign-out-alt" class="ms-2" />
    </v-list-item-title>
    <v-list-item-title v-else @click="dialog = true">
      Zugangscode eingeben
      <font-awesome-icon icon="fas fa-sign-in-alt" class="ms-2" />
    </v-list-item-title>
    <v-dialog v-model="dialog" max-width="600">
      <main-card>
        <LoginForm @closeDialog="dialog = false" />
      </main-card>
    </v-dialog>
  </v-list-item>
</template>

<script>
import authControlMixin from "../../mixins/authControlMixin";
import LoginForm from "@/components/participants/LoginForm";
import MainCard from "@/components/layout/MainCard";
export default {
  components: { MainCard, LoginForm },
  mixins: [authControlMixin],
  props: [],
  data: () => ({
    dialog: false,
  }),
};
</script>

<style lang="scss" scoped>
.v-list-item-title {
  cursor: pointer;
}
</style>
