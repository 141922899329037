import { createApp, configureCompat } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { router } from "./router";
import { BACKEND_URL } from "babel-dotenv";
import VueScreen from "vue-screen";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import axios from "axios";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faSignOutAlt,
  faChevronLeft,
  faChevronRight,
  faCheck,
  faFilePdf,
  faCircleInfo,
  faPen,
  faSignInAlt,
} from "@fortawesome/free-solid-svg-icons";
import "./scss/variables.scss";

// add icons to the library
library.add(
  faSignOutAlt,
  faChevronLeft,
  faChevronRight,
  faCheck,
  faFilePdf,
  faCircleInfo,
  faPen,
  faSignInAlt,
);

// Vue.config.productionTip = false;
axios.defaults.baseURL = BACKEND_URL;

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

// configureCompat({
//   COMPONENT_ASYNC: false,
//   COMPONENT_V_MODEL: false,
// });

const app = createApp(App);

app.use(vuetify);
app.use(VueScreen);
app.use(pinia);
app.use(router);
app.component("FontAwesomeIcon", FontAwesomeIcon);

app.mount("#app");
