<template>
  <Button :large="true" :button-text="'Zurück'" @click="$router.back()">
    <template #icon_left>
      <font-awesome-icon
        icon="fa-solid fa-chevron-left"
        size="lg"
        :class="'me-3'"
      />
    </template>
  </Button>
</template>

<script>
import Button from "@/components/layout/Button";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "BackButton",
  components: {
    Button,
  },
};
</script>
