import Index from "@/pages/Index";
import Questions from "@/pages/Questions";
import Intro from "@/pages/Intro";

import EvaluationOne from "@/pages/EvaluationOne";
import EvaluationTwo from "@/pages/EvaluationTwo";
import EvaluationThree from "@/pages/EvaluationThree";
import EvaluationPdfExport from "@/pages/EvaluationPdfExport";

import CompareOne from "@/pages/CompareOne";
import CompareTwo from "@/pages/CompareTwo";
import CompareThree from "@/pages/CompareThree";

import Impressum from "@/pages/Impressum";
import Datenschutz from "@/pages/Datenschutz";

export const routes = [
  { path: "/", component: Index },
  { path: "/test/", component: Questions },
  { path: "/anleitung/", component: Intro },
  { path: "/auswertung/1/", component: EvaluationOne },
  { path: "/auswertung/2/", component: EvaluationTwo },
  { path: "/auswertung/3/", component: EvaluationThree },
  { path: "/auswertung/export/", component: EvaluationPdfExport },
  { path: "/vergleich/1/", component: CompareOne },
  { path: "/vergleich/2/", component: CompareTwo },
  { path: "/vergleich/3/", component: CompareThree },
  { path: "/impressum/", component: Impressum },
  { path: "/datenschutz/", component: Datenschutz },
  { path: "/:pathMatch(.*)", redirect: "/" },
];
