<template>
  <div>
    <v-container>
      <v-row class="mt-5">
        <v-col cols="12">
          <main-card>
            <h3 class="sub-headline-datenschutz mt-0">Datenschutzerklärung</h3>
            <p class="bigger-font-size">
              Wir freuen uns über Ihre Nutzung unseres Produkts
              Wie-lerne-ich.ch.
              <br />
              Nachfolgend informieren wir Sie über die Verarbeitung Ihrer Daten.
            </p>
            <h3 class="sub-headline-datenschutz">Verantwortlicher</h3>
            <p class="bigger-font-size">
              Verantwortlich für die Verarbeitung von personenbezogenen Daten,
              die beim Besuch dieser Website erfasst werden, ist die Cornelsen
              Verlag GmbH, Mecklenburgische Str. 53, 14197 Berlin
            </p>
            <h3 class="sub-headline-datenschutz">Datenschutzberater</h3>
            <p class="bigger-font-size">
              Die Kontaktdaten des Datenschutzberaters lauten: datenschutz nord
              GmbH, Kurfürstendamm 212, 10719 Berlin,
              office@datenschutz-nord.de.
            </p>
            <h3 class="sub-headline-datenschutz">Nutzungsdaten</h3>
            <p class="bigger-font-size">
              Der Zugang zum Produkt erfolgt über die Eingabe des Lizenzcodes,
              den Sie erworben haben. Während der Nutzung unseres Produkts
              werden folgende Daten erhoben, verarbeitet und gespeichert:
              Häufigkeit der Nutzung, Antwortfortschritt und Testergebnisse.
              <br />
              Diese Daten dienen dazu, die Nutzung der Produktfunktionen zu
              ermöglichen.
            </p>
            <h3 class="sub-headline-datenschutz">Dauer der Datenspeicherung</h3>
            <p class="bigger-font-size">
              Mit Erlöschen der Gültigkeit des Lizenzcodes (im Regelfall 24
              Monate nach Aktivierung) löschen wir die zugehörigen Daten.
            </p>
            <h3 class="sub-headline-datenschutz">Ihre Rechte</h3>
            <p class="bigger-font-size">
              Sie haben das Recht, Auskunft darüber zu verlangen, ob wir
              personenbezogene Daten über Sie bearbeiten. Ferner haben Sie das
              Recht auf Herausgabe und Übertragung Ihrer Daten sowie
              Berichtigung unrichtiger Daten.
            </p>
          </main-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import MainCard from "@/components/layout/MainCard";
export default {
  name: "Datenschutz",
  components: { MainCard },
};
</script>

<style>
p.bigger-font-size {
  font-size: 120%;
}
h3.sub-headline-datenschutz {
  font-size: 140%;
  font-weight: bolder;
  margin-top: 1.5rem;
}
</style>
